import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';
import { Employee } from '../../../modules/cs/common/components/employee';

const CSAlternium: React.FC = () => {
  const data = useStaticQuery(graphql`
    query AlterEmployeesPageQuery {
      alterEmployees: allContentfulEmployee(
        sort: { fields: slug, order: ASC }
        filter: { hasAlternium: { eq: true } }
      ) {
        nodes {
          id
          slug
          hasAlternium
          alterniumInfo {
            name
            type
            skill_name
            description
          }
          krReleaseDate
        }
      }
    }
  `);
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Alternium Reactor</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_reactor.png"
            alt="Beginner gearing"
          />
        </div>
        <div className="page-details">
          <h1>Alternium Reactor</h1>
          <h2>A guide for the Alternium Reactor system in Counter Side.</h2>
          <p>
            Last updated: <strong>17/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Alternium Reactor" />
        <p>
          The Alternium Reactor system was introduced to the game with the
          August 9 2023 update. It basically takes old characters and tries to
          make them more relevant in the current meta by adding some new
          features or passives to their kits.
        </p>
        <p>
          The <strong>Pure Possibilities</strong> item needed to AR a character
          are pretty rare and you can't farm them - they are only obtainable
          through events and login calendar.
        </p>
        <p>
          Below you can find the list of characters who can unlock their
          Alternium Reactor and what bonuses they receive if they do so:
        </p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Character</th>
              <th>Effect</th>
            </tr>
          </thead>
          <tbody>
            {data.alterEmployees.nodes.map((employee, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Employee slug={employee.slug} mode="icon" enablePopover />
                  </td>
                  <td>{employee.alterniumInfo.description}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </DashboardLayout>
  );
};

export default CSAlternium;

export const Head: React.FC = () => (
  <Seo
    title="Alternium Reactor | Counter Side | Prydwen Institute"
    description="A guide for the Alternium Reactor system in Counter Side."
  />
);
